import React, { Component, Fragment } from "react";
import DatePicker from "react-datepicker";
import { Button, OutlinedInput } from "@mui/material";
import { connect } from "react-redux";
import { withRouter } from "utilities/withRouter";
import { Tooltip } from "@mui/material";
import moment from "moment";
import { Icon } from "@ICON_REFERENCE";
import { IconButton, InputAdornment } from "@mui/material";

import { Section } from "@sunwing/components";
import { Localize } from "utilities/localize";
import quote from "services/quote/quote.service";
import PageHeader from "components/common/pageHeader/pageHeader";
import ProductTypeIcon from "components/common/productTypeIcon/productTypeIcon";
import { CONFIGURATION } from "utilities/configuration";
import MarkAsComplete from "components/markAsComplete";


import "./viewItineraryDeviation.scss";

class ViewItineraryDeviation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            isShowDelete: false,
            defaultData: {}
        };
    }

    componentDidMount() {
        this.getDeviationItinerary();
    }

    getDeviationItinerary() {
        let { quoteNumber, itineraryID } = this.props.params;

        this.props.dispatch(
            quote.getDeviationItinerary({ quoteNumber, itineraryID })
        ).then((response) => {
            if (response?.payload?.data) {
                let { data, defaultData } = this.state;
                data = response.payload?.data;
                defaultData = structuredClone(response.payload?.data);
                this.setState({ data, defaultData });
            }
        });

    }

    saveItinerary() {
        let payload = this.state?.data;
        this.props
            .dispatch(quote.updateDeviationItinerary(payload))
            .then((response) => {
                this.getDeviationItinerary();
            });

    }

    markAsCompleteItinerary() {
        let payload = this.state?.data;
        const { leadNumber, quoteNumber } = this.props.params;
        payload.markAsComplete = true;
        this.props
            .dispatch(quote.updateDeviationItinerary(payload))
            .then((response) => {
                this.getDeviationItinerary();
                this.props.navigate("/lead/" + leadNumber + "/quote/" + quoteNumber);
            });

    }

    onCancel() {
        this.getDeviationItinerary();
    }

    setMarkAsComplete = (value) => {
        this.setState({
            isShowDelete: value
        });
    };

    isUpdated = () => {
        let { data, defaultData } = this.state;

        if (data !== null && defaultData && defaultData !== null) {
            if (JSON.stringify(data) !== (JSON.stringify(defaultData))) {
                return true;
            }
            else {
                return false;
            }
        }
        return false;

    };

    handleRemarks = (event, row, value) => {
        let { remarks } = this.state?.data;
        remarks[row][value] = event;
        this.setState({ remarks });
    };

    handleFlight = (event, row, value) => {
        let { data } = this.state;
        data["flights"][row][value] = event;
        this.setState({ data });        
    };

    handlePricingDescription = (event, row, value) => {
        let { data } = this.state;
        data["pricingDetails"][row][value] = event;
        this.setState({ data });

    };

    handleExpiryDate = (event) => {
        let { data } = this.state;
        data["expiryDate"] = event;
        this.setState({ data });
    };


    addButton = () => {
        this.setState({
            data: {
                ...this.state?.data,
                remarks: [...this.state?.data?.remarks, {}]
            }

        });
    };

    addButtonFlight = () => {
        this.setState({
            data: {
                ...this.state?.data,
                flights: [...this.state?.data?.flights, {}]
            }
        });
    };

    addButtonPricing = () => {
        this.setState({
            data: {
                ...this.state?.data,
                pricingDetails: [...this.state?.data?.pricingDetails, {}]
            }
        });
    };

    onClose = () => {
        const { leadNumber, quoteNumber } = this.props.params;
        this.props.navigate("/lead/" + leadNumber + "/quote/" + quoteNumber);
    };

    render() {

        let { option, quoteNumber, quoteDate, expiryDate, quoteBy, noOfAdults, noOfChildren, destinations, departures, products, rooms, itineraryNumber, remarks, flights, pricingDetails, historyLog, markAsComplete } = this.state.data;       
        let { isShowDelete } = this.state;
        let { quoteItinerary } = this.props;
        let selectedDate = expiryDate ? new Date(expiryDate).getTime() + (new Date(expiryDate)?.getTimezoneOffset() * 60000) : null;

        return (
            <Fragment>
                <MarkAsComplete
                    isShow={isShowDelete}
                    data={{ quoteItinerary }}
                    handleMarkAsComplete={() => this.markAsCompleteItinerary()}
                    handleCancel={() => this.setMarkAsComplete(false)}
                />
                <div className="row margin-bottom-4x view-group-itinerary-container">
                    {
                        <div className="row">

                            <Section className="print-hide">
                                <div className="page-main-container margin-bottom-1x">
                                    <PageHeader />
                                </div>
                            </Section>

                            <Section className="print-hide">
                                <div className="row">
                                    <Button
                                        onClick={() => this.onClose()}
                                        data-testid="close-button"
                                        variant="text"
                                        className="pull-right"
                                    >
                                        <Icon
                                            name="close"
                                            className="cursor-pointer icon-16 margin-right"
                                        />
                                        <strong>
                                            {Localize("qma-close")}
                                        </strong>
                                    </Button>
                                </div>

                            </Section>


                            <Section>
                                <div className="table itinerary-information-container">
                                    <div className="table-body">
                                        <div className="table-row first-row">
                                            <div className="table-column table-column-min font-bold">
                                                Option
                                            </div>
                                            <div className="table-column table-column-min">
                                                {option?.code}
                                            </div>
                                            <div className="table-column table-column-min">
                                                &nbsp;
                                            </div>
                                            <div className="table-column table-column-min font-bold">
                                                Quote number
                                            </div>
                                            <div className="table-column table-column-min">
                                                {quoteNumber}
                                            </div>
                                        </div>

                                        <div className="table-row first-row">
                                            <div className="table-column table-column-min font-bold">
                                                Itinerary number
                                            </div>
                                            <div className="table-column table-column-min">
                                                {itineraryNumber}
                                            </div>
                                            <div className="table-column table-column-min">
                                                &nbsp;
                                            </div>
                                            <div className="table-column table-column-min font-bold">
                                                Quote date
                                            </div>
                                            <div className="table-column table-column-min">
                                                {moment(quoteDate).format(CONFIGURATION.DATE_FORMAT)}
                                            </div>
                                        </div>

                                        <div className="table-row first-row">
                                            <div className="table-column table-column-min font-bold">
                                                Expiry date
                                            </div>

                                            <div className="table-column table-column-min">                                              
                                                <DatePicker
                                                    showIcon
                                                    selected={selectedDate}
                                                    onChange={(date) => this.handleExpiryDate(date)}
                                                    disabled={markAsComplete}
                                                    dateFormat="dd MMM yyyy"
                                                    className="date-picker"

                                                />
                                                <Icon name="calendar" className="deviation-cal-icon" />
                                            </div>


                                            <div className="table-column table-column-min">
                                                &nbsp;
                                            </div>
                                            <div className="table-column table-column-min font-bold">
                                                Quote by
                                            </div>
                                            <div className="table-column table-column-min">
                                                {quoteBy?.firstName === null ? "" : quoteBy?.firstName + " " + quoteBy?.lastName}
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="table itinerary-information-container">
                                    <div className="table-body">
                                        <div className="table-row">
                                            <div className="table-column table-column-min font-bold">
                                            <div className="pull-left font-orange margin-right">
                                                <Icon name="room-guests" className="font-14" />
                                            </div>
                                                Travellers
                                            </div>
                                            <div className="table-column table-column-min font-bold">
                                                <div className="font-orange">
                                                    <ProductTypeIcon productType="flight" />
                                                </div>
                                                Departure City
                                            </div>

                                            <div className="table-column table-column-min font-bold">
                                                <div className="pull-left font-orange margin-right">
                                                    <Icon name="map" />
                                                </div>
                                                Destination
                                            </div>
                                            <div className="table-column table-column-min font-bold">
                                                <div className="font-orange">
                                                    <ProductTypeIcon productType="hotel" />
                                                </div>
                                                Hotel / Cruise
                                            </div>
                                            <div className="table-column table-column-min font-bold">
                                                <div className="pull-left font-orange margin-right">
                                                    <Icon name="accommodations" />
                                                </div>
                                                Room / Cabin
                                            </div>
                                        </div>

                                        
                                        <div className="table-row">
                                        <div className="table-column table-column-min">
                                            {noOfAdults + " Adults"}
                                            <br />
                                            {noOfChildren + " Children"}
                                        </div>
                                        <div className="table-column table-column-min">
                                            {departures?.map(departure => {
                                                return (<div>
                                                    {"(" + departure?.code + ") " + departure?.name}
                                                </div>);
                                            })
                                            }
                                        </div>
                                        <div className="table-column table-column-min">

                                            {destinations?.map(destination => {
                                                return (<div>
                                                    {"(" + destination?.code + ") " + destination?.name}
                                                </div>);
                                            })
                                            }
                                        </div>
                                        <div className="table-column table-column-min text-ellipsis">

                                            {products?.map(product => {
                                                return (   
                                                    <Tooltip title={product?.name} placement="top-start">
                                                        <div className="room-name text-ellipsis">
                                                            {product?.name}
                                                        </div>
                                                    </Tooltip>
                                                );
                                            })
                                            }
                                        </div>
                                        <div className="table-column table-column-min text-ellipsis">

                                            {rooms?.map(room => {
                                                return (<
                                                    Tooltip title={room?.name} placement="top-start">
                                                        <div className="room-name text-ellipsis">
                                                            {room?.name}
                                                        </div>
                                                    </Tooltip>
                                                );
                                            })
                                            }
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </Section>

                            <Section>
                                <div className="row margin-top-3x margin-bottom-1x font-bold">
                                    <div className="pull-left font-orange margin-right-1x">
                                        <Icon name="price--n" />
                                    </div>
                                    <div className="pull-left">Deviation remarks</div>
                                </div>

                                <div className="table-row">
                                    Text box has capacity of 2500 characters only. Click “+” to add more remarks. <br /> Tip: Keep phrases as short as possible.
                                </div>


                                <div className="row font-16 margin-top">
                                    <div className="row">
                                        {remarks?.map((item, index) => {
                                            return (<div>
                                                <div className="deviation-col">
                                                    <textarea className="terms-condition-container"
                                                        fullWidth={true}
                                                        maxLength = {2500}
                                                        rows={5}
                                                        name="deviation"
                                                        disabled={markAsComplete}
                                                        id={index}
                                                        value={item?.remarks}
                                                        onChange={(event) => {
                                                            this.handleRemarks(event?.target?.value, index, "remarks");
                                                        }}
                                                        aria-describedby="outlined-deviation"
                                                    />

                                                </div>
                                                <div className="row deviation-col">
                                                    <div className="pull-right font-14 font-light-grey">
                                                        {item?.remarks?.length === undefined ? 0 : item?.remarks?.length}/2500
                                                    </div>
                                                </div>
                                            </div>);
                                        })}
                                        <div className="deviation-action-col">
                                            {!markAsComplete ? <Button
                                                onClick={() => this.addButton()}
                                                className="add-button"
                                                disabled={!remarks || (!remarks[remarks?.length - 1]?.remarks ||
                                                    remarks[remarks?.length - 1]?.remarks === null ||
                                                    remarks[remarks?.length - 1]?.remarks?.length < 1)}
                                                variant="outlined">
                                                <Icon name="plus--n" />
                                            </Button> : ""
                                            }
                                        </div>
                                    </div>
                                </div>


                            </Section>

                            <Section>
                                <div className="row margin-top-3x margin-bottom-1x font-bold">
                                    <div className="pull-left font-orange">
                                        <ProductTypeIcon productType="flight" />
                                    </div>
                                    <div className="pull-left">Flights</div>
                                </div>

                                <div className="row font-16 margin-top">
                                    <div className="row">
                                        {flights?.map((flight, index) => {
                                            return (
                                                <div className="deviation-col-flight">
                                                    <div className="flight-col">
                                                        <div className="flight-col">Flights outbound</div>
                                                        <div className="flight-col">Flights inbound</div>
                                                    </div>

                                                    <OutlinedInput
                                                        fullWidth={true}
                                                        className="col-half container-bg"
                                                        name="outbound"
                                                        disabled={markAsComplete}
                                                        id="outlined-group-name"
                                                        value={flight?.inbound}
                                                        aria-describedby="outlined-deviation"
                                                        onChange={(event) => {
                                                            this.handleFlight(event?.target?.value, index, "inbound");
                                                        }}
                                                    />

                                                    <OutlinedInput
                                                        fullWidth={true}
                                                        className="col-half container-bg"
                                                        name="inbound"
                                                        disabled={markAsComplete}
                                                        id="outlined-group-name"
                                                        value={flight?.outbound}
                                                        aria-describedby="outlined-deviation"
                                                        onChange={(event) => {
                                                            this.handleFlight(event?.target?.value, index, "outbound");
                                                        }}
                                                    />
                                                </div>);
                                        })}

                                        <div className="deviation-action-col">
                                            {!markAsComplete ? <Button
                                                onClick={() => this.addButtonFlight()}
                                                className="add-button"                                                                                               
                                                variant="outlined">
                                                <Icon name="plus--n" />
                                            </Button> : ""
                                            }
                                        </div>
                                    </div>
                                </div>

                            </Section>

                            <Section>
                                <div className="row margin-top-3x margin-bottom-1x font-bold">
                                    <div className="pull-left font-orange margin-right-1x">
                                        <Icon name="price--n" />
                                    </div>
                                    <div className="pull-left">Pricing per person</div>
                                </div>



                                <div className="row">
                                    {pricingDetails?.map((pricing, index) => {
                                        return (
                                            <div>
                                                <div className="table-column itinerary-pricing-col">
                                                    <OutlinedInput
                                                        fullWidth
                                                        name="pricing"
                                                        disabled={markAsComplete}
                                                        id="outlined-pricing"
                                                        className="container-bg"
                                                        placeholder="00.00"
                                                        inputProps={{ maxLength: 10 }}
                                                        startAdornment={
                                                            <InputAdornment>
                                                                <IconButton>
                                                                    <Icon name="price--n" />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        endAdornment={<div className="price-cad">CAD</div>}
                                                        value={pricing?.price}
                                                        aria-describedby="outlined-pricing"
                                                        onChange={(event) => {
                                                            const regex = /^[0-9\b]+$/;
                                                            if (event.target.value === "" || regex.test(event.target.value)) {
                                                                let value = event.target.value === "" ? null : parseInt(event.target.value, 10);
                                                                this.handlePricingDescription(value, index, "price");
                                                            }
                                                        }}

                                                    />

                                                </div>
                                                <div className="table-column itinerary-pricing-col-desc">
                                                    <OutlinedInput
                                                        fullWidth={true}
                                                        name="description"
                                                        disabled={markAsComplete}
                                                        id="outlined-description"
                                                        className="container-bg"
                                                        inputProps={{ maxLength: 70 }}
                                                        placeholder="Price description"
                                                        value={pricing?.priceDescription}
                                                        aria-describedby="outlined-description"
                                                        onChange={(event) => {
                                                            this.handlePricingDescription(event?.target?.value, index, "priceDescription");
                                                        }}
                                                    />

                                                </div>

                                                <div className="row deviation-col">
                                                    <div className="pull-right font-14 font-light-grey">
                                                        {pricing?.priceDescription?.length === undefined ? 0 : pricing?.priceDescription?.length}/70
                                                    </div>
                                                </div>
                                            </div>);
                                    })}
                                    <div className="deviation-action-col">
                                        {!markAsComplete ? <Button
                                            onClick={() => this.addButtonPricing()}
                                            className="add-button"                                            
                                            variant="outlined">
                                            <Icon name="plus--n" />
                                        </Button> : ""
                                        }
                                    </div>
                                </div>



                            </Section>

                            <Section>
                                {!markAsComplete ?
                                    <div className="row margin-top-3x margin-bottom-1x">
                                        <div className="pull-right">
                                            <Button className="btn-green" variant="contained" disabled={false} onClick={() => this.setMarkAsComplete(true)}>
                                                Mark as complete
                                            </Button>
                                        </div>

                                        <div className="pull-right margin-right-1x">
                                            <Button variant="contained" disabled={!this.isUpdated()} onClick={() => this.saveItinerary()}>Save</Button>
                                        </div>

                                        {this.isUpdated() ? <div className="pull-right margin-right-1x">
                                            <Button variant="outlined" onClick={() => this.onCancel()}>Cancel</Button>
                                        </div> : ""}
                                    </div> : ""
                                }
                            </Section>

                            {this.state?.data?.historyLog?.length > 0 ?<Section>
                                <div className="row margin-top-2x margin-bottom-1x">
                                    <h3 className="font-bold">History Log</h3>
                                </div>

                                <div className="table">
                                    <div className="table-head">
                                        <div className="table-row font-bold">
                                            <div className="table-column view-quote-itinerary-updated-by">
                                                Updated by
                                            </div>
                                            <div className="table-column view-quote-itinerary-updated-on">
                                                Updated on
                                            </div>
                                            <div className="table-column view-quote-itinerary-log">Log</div>
                                        </div>
                                    </div>
                                    <div className="table-body">
                                        {historyLog?.map((history, historyIndex) => (
                                            <div key={historyIndex} className="table-row">
                                                <div className="table-column view-quote-itinerary-updated-by">
                                                    {history.updatedBy}
                                                </div>
                                                <div className="table-column view-quote-itinerary-updated-on">
                                                    {moment(history.updatedOn).format(
                                                        CONFIGURATION.DATE_FORMAT_TIME
                                                    )}
                                                </div>
                                                <div className="table-column view-quote-itinerary-log">
                                                    {history?.log}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Section> : ""}
                        </div>
                    }

                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    quoteItinerary: state.quoteItinerary
});

export default connect(mapStateToProps)(withRouter(ViewItineraryDeviation));
