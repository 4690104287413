export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_FAIL = "GET_CUSTOMER_FAIL";

export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";

export const GET_AGENCY_SUCCESS = "GET_AGENCY_SUCCESS";
export const GET_AGENCY_FAIL = "GET_AGENCY_FAIL";

export const GET_CONTENTFUL_SUCCESS = "GET_CONTENTFUL_SUCCESS";
export const GET_CONTENTFUL_FAIL = "GET_CONTENTFUL_FAIL";

export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_FAIL = "GET_CITIES_FAIL";

export const GET_DEPARTURE_CITIES_SUCCESS = "GET_DEPARTURE_CITIES_SUCCESS";
export const GET_DEPARTURE_CITIES_FAIL = "GET_DEPARTURE_CITIES_FAIL";
export const RESET_DEPARTURE = "RESET_DEPARTURE";

export const GET_GROUP_TYPES_SUCCESS = "GET_GROUP_TYPES_SUCCESS";
export const GET_GROUP_TYPES_FAIL = "GET_GROUP_TYPES_FAIL";

export const GET_PRODUCT_TYPES_SUCCESS = "GET_PRODUCT_TYPES_SUCCESS";
export const GET_PRODUCT_TYPES_FAIL = "GET_PRODUCT_TYPES_FAIL";

export const GET_HOTELS_SUCCESS = "GET_HOTELS_SUCCESS";
export const GET_HOTELS_FAIL = "GET_HOTELS_FAIL";

export const GET_ROOM_TYPES_SUCCESS = "GET_ROOM_TYPES_SUCCESS";
export const GET_ROOM_TYPES_FAIL = "GET_ROOM_TYPES_FAIL";

export const GET_CABINS_SUCCESS = "GET_CABINS_SUCCESS";
export const GET_CABINS_FAIL = "GET_CABINS_FAIL";

export const GET_CRUISE_SUCCESS = "GET_CRUISE_SUCCESS";
export const GET_CRUISE_FAIL = "GET_CRUISE_FAIL";

export const GET_QUOTES_SUCCESS = "GET_QUOTES_SUCCESS";
export const GET_QUOTES_FAIL = "GET_QUOTES_FAIL";

export const UPDATE_QUOTE_ITINERARY_SUCCESS = "UPDATE_QUOTE_ITINERARY_SUCCESS";
export const UPDATE_QUOTE_ITINERARY_FAIL = "UPDATE_QUOTE_ITINERARY_FAIL";

export const GET_QUOTE_TIMED_OUT = "GET_QUOTE_TIMED_OUT";
export const GET_QUOTE_SUCCESS = "GET_QUOTE_SUCCESS";
export const GET_QUOTE_FAIL = "GET_QUOTE_FAIL";

export const GET_QUOTE_ITINERARY_SUCCESS = "GET_QUOTE_ITINERARY_SUCCESS";
export const GET_QUOTE_ITINERARY_FAIL = "GET_QUOTE_ITINERARY_FAIL";

export const QUOTE_RESET = "QUOTE_RESET";

export const SAVE_CITIES = "SAVE_CITIES";
export const SAVE_HOTELS = "SAVE_HOTELS";
export const RESET_PACKAGE_DETAILS = " RESET_PACKAGE_DETAILS";
export const SAVE_HOTEL_ROOMS = "SAVE_HOTEL_ROOMS";
export const SET_PACKAGE_DETAILS_DATA = "SET_PACKAGE_DETAILS_DATA";
export const RESET_HOTEL_DETAILS = "RESET_HOTEL_DETAILS";
export const RESET_HOTEL_ROOM_DETAILS = "RESET_HOTEL_ROOM_DETAILS";
export const RESET_DESTINATION_DETAILS="RESET_DESTINATION_DETAILS";
export const UPDATE_VACATION_DURATION = "UPDATE_VACATION_DURATION";

export const SET_GROUP_DETAILS = "SET_GROUP_DETAILS";

export const SET_QUOTE_OPTION = "SET_QUOTE_OPTION";
export const CREATE_QUOTE_OPTION = "CREATE_QUOTE_OPTION";
export const UPDATE_QUOTE_OPTION = "UPDATE_QUOTE_OPTION";
export const DELETE_QUOTE_OPTION = "DELETE_QUOTE_OPTION";
export const CANCEL_QUOTE_OPTION = "CANCEL_QUOTE_OPTION";
export const UPDATE_HOTEL_ENABLED = "UPDATE_HOTEL_ENABLED";

export const GET_SFDC_SUCCESS = "GET_SFDC_SUCCESS";
export const GET_SFDC_FAIL = "GET_SFDC_FAIL";

export const GET_FLIGHTS_DATES_SUCCESS = "GET_FLIGHTS_DATES_SUCCESS";
export const GET_FLIGHTS_DATES_RESET = "GET_FLIGHTS_DATES_RESET";
export const GET_FLIGHTS_DATES_FAIL = "GET_FLIGHTS_DATES_FAIL";

export const GET_EMAIL_LIST_SUCCESS = "GET_EMAIL_LIST_SUCCESS";
export const GET_EMAIL_LIST_FAIL = "GET_EMAIL_LIST_FAIL";
export const GET_EMAIL_ADD_SUCCESS = "GET_EMAIL_ADD_SUCCESS";
export const GET_EMAIL_ADD_FAIL = "GET_EMAIL_ADD_FAIL";

export const GET_RE_QUOTE_SUCCESS = "GET_RE_QUOTE_SUCCESS";
export const GET_RE_QUOTE_FAIL = "GET_RE_QUOTE_FAIL";

export const GET_CLONE_SUCCESS = "GET_CLONE_SUCCESS";
export const GET_CLONE_FAIL = "GET_CLONE_FAIL";

export const GET_DEVIATION_DESTINATION_CITIES_SUCCESS = "GET_DEVIATION_DESTINATION_CITIES_SUCCESS";
export const GET_DEVIATION_DESTINATION_CITIES_FAIL = "GET_DEVIATION_DESTINATION_CITIES_FAIL";

export const GET_DEVIATION_DEPARTURE_CITIES_SUCCESS = "GET_DEVIATION_DEPARTURE_CITIES_SUCCESS";
export const GET_DEVIATION_DEPARTURE_CITIES_FAIL = "GET_DEVIATION_DEPARTURE_CITIES_FAIL";
export const GET_GROUP_CONTRACT_SUCCESS = "GET_GROUP_CONTRACT_SUCCESS";
export const GET_GROUP_CONTRACT_FAIL = "GET_GROUP_CONTRACT_FAIL";

export const GET_GROUP_CONTRACT_EMAIL_SUCCESS = "GET_GROUP_CONTRACT_EMAIL_SUCCESS";
export const GET_GROUP_CONTRACT_EMAIL_FAIL = "GET_GROUP_CONTRACT_EMAIL_FAIL";

export const GET_GROUP_CONTRACT_UPDATE_SUCCESS = "GET_GROUP_CONTRACT_SUCCESS";
export const GET_GROUP_CONTRACT_UPDATE_FAIL = "GET_GROUP_CONTRACT_FAIL";

export const GET_EMAIL_SUCCESS = "GET_EMAIL_SUCCESS";
export const GET_EMAIL_FAIL = "GET_EMAIL_FAIL";

export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_FAIL = "GET_INVOICE_FAIL";

export const GET_INVOICE_EMAIL_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_EMAIL_FAIL = "GET_INVOICE_FAIL";

export const GET_QUOTE_LETTER_UPDATE_SUCCESS = "GET_QUOTE_LETTER_UPDATE_SUCCESS";
export const GET_QUOTE_LETTER_UPDATE_FAIL = "GET_QUOTE_LETTER_UPDATE_FAIL";

export const SET_LOADER_START = "SET_LOADER_START";
export const SET_LOADER_STOP = "SET_LOADER_STOP";

export const API_CALL_START = "API_CALL_START";
export const API_CALL_FAIL = "API_CALL_FAIL";