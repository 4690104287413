import { OktaAuth } from "@okta/okta-auth-js";
import jwt_decode from "jwt-decode";
import { oktaAuthConfig } from "modules/okta/oktaConfig";

async function getOktaToken() {
  const authClient = new OktaAuth(oktaAuthConfig);
  const accessToken = await authClient.tokenManager.get("accessToken");

  return accessToken;
}

async function validateOkta() {
  const authClient = new OktaAuth(oktaAuthConfig);

  if (await authClient.session.exists()) {
    let response = await authClient.token.getWithoutPrompt();

    authClient.tokenManager.setTokens(response.tokens);
    return true;
  }

  return false;
}

function getUserType() {
  let authentication = localStorage.getItem("okta-token-storage");
  let accessToken = JSON.parse(authentication)?.accessToken?.accessToken;

  if (accessToken) {
    let decodedToken = jwt_decode(accessToken);
    let qmaGroups = decodedToken["qma.groups"];
    let agrp = decodedToken["agrp"];
    let user = "CUSTOMER";

    if (agrp) {
      user =
        agrp?.length > 0 && agrp[0].toUpperCase() === "AGENTS" ? "AGENT" : "";
    }
    if (qmaGroups) {
      user = qmaGroups?.length > 0 && qmaGroups[0].toUpperCase();
    }

    return user;
  }
}

function isIQMUserRole(user) {
  return user === "IQM";
}

function isCustomerUser(){
  let userData = getUserType();
  return userData === "CUSTOMER";
}

function isIQMUser() {
  let userData = getUserType();
  return userData === "IQM";
}

function getNumberPostFixText(index) {
  return index === 0 ? "st" : index === 1 ? "nd" : index === 2 ? "rd" : "th";
}

function getNumberByLanguage(index) {
  const localize = localStorage.getItem("userLanguage");
  if (localize === "en") {
    return index === 0 ? "st" : index === 1 ? "nd" : index === 2 ? "rd" : "th";
  } else {
    return index === 0 ? "er" : index === 1 ? "e" : index === 2 ? "e" : "e";
  }
}

function translateCurrencyFormat(amount) {
  const localize = localStorage.getItem("userLanguage");
  if (localize === "en") {
    return "$" +  amount;
  } else {
    return amount + "$";
  }
}

function getContentfulDataById(collection, id) {
  if (collection) {
    const index = collection.findIndex((item) => item?.key === id);
    const contentfulData = collection[index];

    return contentfulData?.value;
  }

  return null;
}

function calculateDuration(start, end) {
  if (start && end) {
    let startDate = Date.parse(start);
    let endDate = Date.parse(end);
    let durationDays = parseInt((endDate - startDate) / (1000 * 60 * 60 * 24),10);

    return durationDays;
  }
  return 0;
}

function scrollToItinerary(scrollElementId) {
  const element = document.getElementById(scrollElementId);
  const top = element.offsetTop - 80;

  window?.scrollTo(0, top);
}

function scrollToSection(scrollElementId) {
  const element = document.getElementById(scrollElementId);
  const top = element?.offsetTop;

  window?.scrollTo(0, top);
}

function getProductType(props) {
  let { data } = props;
  let { quote, optionIndex, deviationOptionIndex } = data;

  let { productTypes, deviationTypes } = quote;
  if (productTypes && productTypes?.length > 0 && productTypes[optionIndex]?.productType?.code !== "" && productTypes[optionIndex]?.productType?.code !== "Deviation") {
    return productTypes[optionIndex];
  }
  else if (deviationTypes && deviationTypes.length > 0 && deviationTypes[deviationOptionIndex]?.productType?.code === "Deviation") {
    return deviationTypes[deviationOptionIndex];
  }
  else {
    return null;
  }
}

function getProductTypeFromState(state) {
  let { quote, optionIndex } = state;
  let { productTypes } = quote;

  if (productTypes && productTypes?.length > 0) {
    return productTypes[optionIndex];
  }
  return null;
}

function getDeviationProductTypeFromState(state) {
  let { quote, deviationOptionIndex } = state;
  let { deviationTypes } = quote;
  if (deviationTypes && deviationTypes?.length > 0) {
    return deviationTypes[deviationOptionIndex];
  }
  return null;
}
function getDeviationProducts(quoteOption) {
  let products = [];

  quoteOption?.deviationItinerary?.deviationRequest?.forEach((option) => {
      let destination = option.destination;
      option?.products?.forEach(product => {
          let p = {...product, destination};
          if(products.length != 2) products.push(p);
      });
  });
  return products;
}

const commonUtilities = {
  isCustomerUser,
  isIQMUser,
  isIQMUserRole,
  getUserType,
  getOktaToken,
  validateOkta,
  getProductType,
  scrollToSection,
  calculateDuration,
  scrollToItinerary,
  getNumberPostFixText,
  getContentfulDataById,
  getProductTypeFromState,
  getDeviationProductTypeFromState,
  getNumberByLanguage,
  translateCurrencyFormat,
  getDeviationProducts
};

export default commonUtilities;
