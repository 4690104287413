import * as actions from "./actions";

export default function apiCallReducer(state = [], action) {
    switch (action.type) {

    case actions.API_CALL_START:
    case actions.API_CALL_FAIL:
    {
        return action.payload;
    }
    default:
        return state;
    }
}
